import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/parataxic.org/parataxic.org/www/src/templates/MDXPage.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Living list of favorite quotes`}</h1>
    <blockquote>
      <p parentName="blockquote">{`We all know that the work of analysis consists very largely in helping the
patient to rid himself of the tensions, patterned attitudes, and expectations
which have arisen in the vicissitudes of the past and are impinging
unhelpfully upon current situations, so much so that they actually distort his
appreciation of and his reactive possibilities to the present problems of his
life...`}</p>
    </blockquote>
    <p>{`Greenacre, P. (1954). `}<em parentName="p">{`The role of transference: practical considerations in
relation to psychoanalytic therapy`}</em>{`. Journal of the American Psychoanalytic
Association, 2, 683. `}<a parentName="p" {...{
        "href": "https://doi.org/10.1177/000306515400200406"
      }}>{`https://doi.org/10.1177/000306515400200406`}</a></p>
    <blockquote>
      <p parentName="blockquote">{`Emotion can can be drained out of the old patterns by new experience, but
water can always flow again in the old dried up water courses'. You cannot
give anyone a different history.`}</p>
    </blockquote>
    <p>{`Guntrip, H. (1975) `}<em parentName="p">{`My Experience of Analysis with Fairbairn and Winnicott —
(How Complete a Result Does Psycho-Analytic Therapy Achieve?`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`Theory does not seem to me to be the major concern. It is a useful servant but
a bad master, liable to produce orthodox defenders of every variety of the
faith... If our theory is too rigid, it is likely to conceptualize our ego
defences`}</p>
    </blockquote>
    <p>{`Ibid.`}</p>
    <blockquote>
      <p parentName="blockquote">{`When such a situation arises, the central ego, so to speak, sits back in the
dress-circle and describes the dramas enacted upon the stage of inner reality
without any effective participation in them. At the same time it derives
considerable narcissistic satisfaction from being the recorder of remarkable
events and identifying itself with the analyst as observer while asserting a
superiority over the analyst as mere observer by reason of the fact that it is
not merely observing, but also furnishing the material for observation. This
procedure is really a masterpiece of defensive technique—one to which schizoid
individuals are only too ready to resort at the best of times,`}</p>
    </blockquote>
    <p>{`Fairbairn, W. R. D. `}<em parentName="p">{`Psychoanalytic Studies of the Personality`}</em>{` (p. 85). Taylor
and Francis. Kindle Edition.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      