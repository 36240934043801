import * as React from "react";

import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import {
  GridWithSidebarStyling,
  SEO,
  ToCSidebar,
  useToC,
} from "@parataxic/shared-ui";

import "@parataxic/shared-ui/src/styles/fonts.css";

import { ParataxicMDXProvider } from "../components/MDXProvider";

interface PageProps {
  data: {
    mdx: GatsbyTypes.SitePageContextMdx & {
      firstHeading?: { value: string }[];
    };
  };
}

const MDXPage: React.FC<PageProps> = ({ children, data, pageContext }) => {
  const mdx = data ?? pageContext?.mdx;
  const content = mdx?.body ? mdx.body : children;
  const title: string | boolean =
    mdx?.frontmatter?.title !== ""
      ? mdx?.frontmatter?.title
      : mdx?.firstHeading?.[0]?.value !== ""
        ? mdx?.firstHeading?.[0]?.value
        : (mdx?.headings?.[0]?.value ?? false);
  useToC({ querySelector: "#content nav div ul li a" });
  return (
    <div style={GridWithSidebarStyling} id="content" role="article">
      {title && <SEO title={title} />}
      <ToCSidebar
        mdx={mdx}
        variant="grid-with-sidebar"
        indent="0.15rem"
        initialDepth={1}
      />
      <ParataxicMDXProvider
        style={{
          alignSelf: "start",
          gridColumn: 1,
          gridRow: 1,
          maxWidth: "936px",
          minWidth: 0,
        }}
      >
        {mdx?.body ? <MDXRenderer>{content}</MDXRenderer> : <>{children}</>}
      </ParataxicMDXProvider>
    </div>
  );
};

graphql`
  query MDXPage($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        description
      }
      tableOfContents(maxDepth: 4)
      firstHeading: headings(depth: h1) {
        value
      }
    }
  }
`;

export default MDXPage;
