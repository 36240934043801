/* eslint-disable react/display-name */
import * as React from "react";

import { MDXProvider as MyMDXProvider } from "@parataxic/shared-ui";

import { PopoverSiteLink } from "./Popover";

export const ParataxicMDXProvider: React.Component = ({
  children,
  components = {},
  ...rest
}: React.ComponentProps<typeof MyMDXProvider>) => (
  <MyMDXProvider
    components={{
      ...components,
      a: ({ children, ...props }) => (
        <PopoverSiteLink {...props}>{children}</PopoverSiteLink>
      ),
    }}
    {...rest}
  >
    {children}
  </MyMDXProvider>
);
